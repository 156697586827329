/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'patch-plus-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M10.067.87a2.89 2.89 0 00-4.134 0l-.622.638-.89-.011a2.89 2.89 0 00-2.924 2.924l.01.89-.636.622a2.89 2.89 0 000 4.134l.637.622-.011.89a2.89 2.89 0 002.924 2.924l.89-.01.622.636a2.89 2.89 0 004.134 0l.622-.637.89.011a2.89 2.89 0 002.924-2.924l-.01-.89.636-.622a2.89 2.89 0 000-4.134l-.637-.622.011-.89a2.89 2.89 0 00-2.924-2.924l-.89.01zM8.5 6v1.5H10a.5.5 0 010 1H8.5V10a.5.5 0 01-1 0V8.5H6a.5.5 0 010-1h1.5V6a.5.5 0 011 0"/>',
    },
});
